<template>
  <div class="popup_wrap" style="width:1050px; height:auto;">
    <!--  popup_wrap -->
    <button class="layer_close" @click="layerClose()">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.NEWB010P020.001') }}</h1><!-- 불러오기 -->
      <div class="content_box mt10">
        <!-- content_box -->
        <form id="bookingCall" @submit.prevent>
          <div class="booking_pop_wrap">
            <ul class="grid">
              <li>
                <div class="form_wrap">
                  <label for="">{{ $t('msg.CSBK100.012') }}</label><!-- 출발 -->
                  <span style="display:inline-block">
                    <e-auto-complete-place @change="changePol" :ctr-cd="formData.polCtrCd" :plc-cd="formData.polPortCd" class="wid150" />
                  </span>
                </div>
              </li>
              <li>
                <div class="form_wrap">
                  <label for="">{{ $t('msg.CSBK100.013') }}</label><!-- 도착 -->
                  <span style="display:inline-block">
                    <e-auto-complete-place @change="changePod" :ctr-cd="formData.podCtrCd" :plc-cd="formData.podPortCd" class="wid150" />
                  </span>
                </div>
              </li>
              <li>
                <div class="form_wrap">
                  <label for="">{{ $t('msg.NEWB010P020.004') }}</label><!-- 실화주 -->
                  <input type="text" id="callInput" class="wid150" v-model="formData.actShprCstEnm">
                </div>
              </li>
            </ul>
            <ul class="grid t3 mt10">
              <e-date-range-picker
                :sdate="formData.fromDt"
                :edate="formData.toDt"
                @change="changeDateRange"
                :title="this.$t('msg.NEWB010P020.005')"
              />
            </ul>
            <div class="booking_search_btn">
              <button class="button sm blue" type="button" @click="search()">{{ $t('msg.NEWB010P020.006') }}</button><!-- 검색 -->
            </div>
          </div>
        </form>
      </div><!-- content_box // -->
      <div class="content_box mt10">
        <div class="flex_box">
          <span class="tot_num">{{ $t('msg.NEWB010P020.007') }} : <span class="num">{{ total }}</span></span><!-- Total -->
        </div>
        <div id="realgrid" style="width: 100%; height: 200px" />
      </div>
    </div><!-- popup_cont -->
  </div>
</template>

<script>
// TODO
// date 적용하기.

import commons from '@/api/services/commons'
import bookings from '@/api/rest/trans/bookings'
import { GridView, LocalDataProvider } from 'realgrid'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import EDateRangePicker from '@/components/common/EDateRangePicker'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  {
    fieldName: 'bkgNo',
    dataType: 'text'
  },
  {
    fieldName: 'actShprCstEnm',
    dataType: 'text'
  },
  {
    fieldName: 'polPortNm',
    dataType: 'text'
  },
  {
    fieldName: 'podPortNm',
    dataType: 'text'
  },
  {
    fieldName: 'cntrQty',
    dataType: 'text'
  },
  {
    fieldName: 'cgoTypCd',
    dataType: 'text'
  },
  {
    fieldName: 'cmdtDsc1',
    dataType: 'text'
  }
]

export const columns = [
  {
    name: 'bkgNo',
    fieldName: 'bkgNo',
    type: 'text',
    width: '150',
    header: {
        text: 'Booking No.'
    },
    editable: false,
    readOnly: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        let str = ''
        str = '<a style="text-decoration:underline;color:#075bb9" @click="selectedRow">' + data + '</a>'
        return str
      }
    }
  },
  {
    name: 'actShprCstEnm',
    fieldName: 'actShprCstEnm',
    type: 'text',
    width: '100',
    header: {
      text: ''
    },
    readOnly: true
  },
  {
    name: 'polPortNm',
    fieldName: 'polPortNm',
    type: 'text',
    width: '150',
    header: {
      text: ''
    },
    readOnly: true
  },
  {
    name: 'podPortNm',
    fieldName: 'podPortNm',
    type: 'text',
    width: '150',
    header: {
      text: ''
    },
    readOnly: true
  },
  {
    name: 'cntrQty',
    fieldName: 'cntrQty',
    type: 'text',
    width: '100',
    header: {
      text: 'Container'
    },
    readOnly: true
  },
  {
    name: 'cgoTypCd',
    fieldName: 'cgoTypCd',
    header: { text: 'Cargo Type' },
    width: '120',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const cgoTypCd = grid.getValue(idx, 'cgoTypCd')
        const btnHtml = []
        if (cgoTypCd.includes(',')) {
          const arrCagoTypCd = cgoTypCd.split(',')
          arrCagoTypCd.forEach(cd => {
            btnHtml.push('<p>' + thisApp.getCodeNm('01038', cd.trim()) + '</p>')
          })
        } else {
            btnHtml.push('<p>' + thisApp.getCodeNm('01038', cgoTypCd.trim()) + '</p>')
        }
        return btnHtml.join(' ')
      }
    }
  },
  {
    name: 'cmdtDsc1',
    fieldName: 'cmdtDsc1',
    type: 'text',
    width: '100',
    header: {
      text: 'Commodity'
    },
    readOnly: true
  }
]

export default ({
  name: 'BookingCallPop',
  components: {
    EAutoCompletePlace,
    EDateRangePicker
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          polPortNm: '',
          podPortNm: '',
          actShprCstEnm: '',
          podCtrCd: '',
          podPlcCd: '',
          polCtrCd: '',
          polPlcCd: ''
        }
      }
    }
  },
  data () {
    return {
      items: [],
      formData: {
        podCtrCd: '',
        polCtrCd: '',
        podPortCd: '',
        polPortCd: '',
        actShprCstEnm: '',
        fromDt: '',
        toDt: '',
        templateFlag: ''
      },
      total: 0,
      commonCodes: {}
    }
  },
  created () {
    const parentInfo = this.parentInfo
    const formData = this.formData

    formData.polCtrCd = parentInfo.polCtrCd
    formData.polPortCd = parentInfo.polPortCd
    formData.podCtrCd = parentInfo.podCtrCd
    formData.podPortCd = parentInfo.podPortCd
    formData.templateFlag = parentInfo.templateFlag || ''

    formData.fromDt = this.$ekmtcCommon.getAddDay('', -14) // 시작일자 없으면 오늘부터 -6
    formData.toDt = this.$ekmtcCommon.getDateToStrDelim(new Date(), '')

    this.search()

    this.initCommCodes()
  },
  mounted: function () {
      window.thisApp = this
      const $vm = this
      //데이터를 담는 객체
      provider = new LocalDataProvider(true)
      //데이터를 보여주기 위한 객체
      gridView = new GridView('realgrid')
      gridView.setDataSource(provider)
      //그리드 필드 생성
      if (this.formData.templateFlag === 'SR') {
        fields[0].fieldName = 'srRno'
        columns[0].name = 'srRno'
        columns[0].header = { text: 'SR No.' }
        columns[0].fieldName = 'srRno'
      }

      provider.setFields(fields)
      //column명
      gridView.setColumns(columns)

      gridView.displayOptions.fitStyle = 'fill'
      gridView.displayOptions.rowHeight = 30
      gridView.setEditOptions({
        editable: false,
        updatable: false
      })

      //인디케이터 없애기
      gridView.setRowIndicator({
        visible: false
      })
      //상태바 없애기
      gridView.setStateBar({
        visible: false
      })
      //체크바 없애기
      gridView.setCheckBar({
        visible: false
      })
      //Grid 메소드
      $vm.gridEvents()
      $vm.setMessageProperty()
  },
  methods: {
    // 공통코드 불러오기
    async initCommCodes () {
      const cdId = ['01038']
      this.commonCodes = await commons.getCommonsCodesCommon(cdId)
    },
    getCodeNm (cdId, cd) {
      const list = this.commonCodes[cdId]
      const vo = list.find(vo => vo.cd === cd)
      if (vo === undefined) {
        return ''
      } else {
        return vo.cdNm
      }
    },
    search: function () {
      this.items = []
      //api 호출
      if (this.formData.podCtrCd !== '' || this.formData.polCtrCd !== '' || this.formData.actShprCstEnm !== '' || this.formData.fromDt !== '' || this.formData.toDt !== '') {
        bookings.getBookingCall(this.formData).then((result) => {
          const list = result.data
          list.forEach((item) => {
            this.items.push(item)
          })

          if (list.length !== 0) {
            //해당 검색어를 검색한 리스트 to array

            //그리드 데이터 세팅
            provider.setRows(this.items)

            //total
            this.total = list.length
            //alert('적용되었습니다.')
            return false
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.NEWB010P020.008'))
          }
        })
        return false
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G010.020'))
      }
    },
    gridEvents () {
      const TH = this
      gridView.onCellClicked = function (grid, clickData) {
        const current = gridView.getCurrent()
        const fieldName = clickData.fieldName
        const clickedField = provider.getValue(current.dataRow, current.fieldName)
        //선택 버튼 제어
        if (fieldName === 'bkgNo' || fieldName === 'srRno') {
          if (clickedField === '1') {
            // alert('선택불가')
          } else {
            const selectedData = provider.getValues(clickData.dataRow)
            if (selectedData.length > 0) {
              const obj = {
                bkgNo: selectedData[0],
                srRno: selectedData[0],
                actShprCstEnm: selectedData[1],
                polPortNm: selectedData[2],
                podPortNm: selectedData[4],
                cntrQty: selectedData[5],
                cgoTypCd: selectedData[6],
                cmdtDsc1: selectedData[7]
              }
              TH.$emit('selectFunc', obj)
              TH.$emit('close')
            }
          }
        }
      }
    },
    layerClose: function () {
      this.$emit('close')
      return false
    },
    changePol (vo) {
      const def = {
        ctrCd: '',
        plcCd: ''
      }
      const tvo = { ...def, ...vo }

      this.formData.polCtrCd = tvo.ctrCd
      this.formData.polPortCd = tvo.plcCd
    },
    changePod (vo) {
      const def = {
        ctrCd: '',
        plcCd: ''
      }
      const tvo = { ...def, ...vo }

      this.formData.podCtrCd = tvo.ctrCd
      this.formData.podPortCd = tvo.plcCd
    },
    changeDateRange (s, d) {
      this.formData.fromDt = s
      this.formData.toDt = d
    },
    setMessageProperty () {
      const actShprCstEnmTxt = this.$t('msg.NEWB010P020.009')
      const polTxt = this.$t('msg.VOSD100.002')
      const podTxt = this.$t('msg.VOSD100.003')

      gridView.setColumnProperty('actShprCstEnm', 'header', actShprCstEnmTxt)
      gridView.setColumnProperty('polPortNm', 'header', polTxt)
      gridView.setColumnProperty('podPortNm', 'header', podTxt)
    }
  }
})
</script>
